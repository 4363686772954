import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const userToken = localStorage.getItem(environment.authTokenKey);
const httpHeaders = {
   'Content-Type': 'text/plain'
};

const headers = new HttpHeaders({
   'Authorization': 'Bearer ' + userToken,
   'Content-Type': 'text/plain'
});

@Injectable({
   providedIn: 'root'
})

// ---------------------------------------------------------------
// GeneralService es para uso exclusivo de empleados autenticados
// ---------------------------------------------------------------

export class GeneralService {

   constructor(private http: HttpClient) {}

// ----------------------------------------
// FUNCIONES PARA DASHBOARD
// ----------------------------------------

   DashboardLeer() {
      return this.http.get<any>(environment.API_URL + 'int/dashboard?token=' + userToken, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA ESTADÍSTICA MENSUAL
// ----------------------------------------

   EstadisticaMensualLeer() {
      return this.http.get<any>(environment.API_URL + 'estadistica-mensual?token=' + userToken);
   }

// ----------------------------------------
// FUNCIONES PARA ACTIVIDADES ECONÓMICAS
// ----------------------------------------

   ActivEconomBuscarContrib(termino1: string, termino2: number): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/contribuyente?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2);
   }
   TeleconsultaBuscarPaciente(termino1: string, termino2: number): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'teleconsulta/buscar/paciente?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2);
   }
   BuscarTrabajador(termino1: string, termino2: number): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'trabajador/buscar/trabajador?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2);
   } 
   TeleconsultaBuscarhPaciente(termino1: string, termino2: number): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'teleconsulta/buscar/hpaciente?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2);
   }
   PreAprovado(termino1: string, termino2: number): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'aprob/buscar/preaprob?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2);
   }
   ActivEconomBuscarActivPorContrib(id: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/actividades-contribuyente?token=' + userToken + '&id=' + id);
   }

   ActivEconomGuardarActiv(data: any): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/guardar/actividad?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   ConsGuardar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'consguardar/guardar/teleconsuta?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   ReglGuardar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'reglamoguardar/guardar/reglamo?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   FarmGuardar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'farmGuardar/guardar/farmacia?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   ReemGuardar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'reemGuardar/guardar/reembolso?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   AmpGuardar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'ampguardar/guardar/amp?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   ConsGuardarsm(data: any): Observable<any> {
      const ruta = environment.API_URL + 'consguardar/guardar/servmed?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   CallGuardar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'consguardar/guardar/call?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   FunGuardar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'consguardar/guardar/funeraria?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   AfilGuardar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'afilguardar/guardar/afiliado?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   AmbGuardar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'consguardar/guardar/ambulacia?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   AcartGuardar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'consguardar/guardar/acart?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   ActivEconomEliminarActiv(data: any): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/eliminar/actividad?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }

   ActivEconomBuscarGrupo(): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/grupos?token=' + userToken);
   }

   ActivEconomBuscarSubgrupo(grupo: number): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/subgrupos?token=' + userToken + '&q=' + grupo);
   }
   NunTempActualizar(): Observable<any> {
      return this.http.post(environment.API_URL + 'actividades-economicas/nuntempactualizar?token=' + userToken,  { headers: httpHeaders });
   }
   NunTempLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nuntemp?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   NunTempELeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nunetemp?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   NunTempsmLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nunsmtemp?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   NunTempCallLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nuntempcall?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   NunTempfLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nunftemp?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   NunTempaLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nunatemp?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   NunTempcLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nunctemp?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   NunTempfaLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nunfatemp?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   NunTempreemLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nunreemtemp?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   
   NunTempapmLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nunamptemp?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   NunTempreglLeer(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/nunreglamtemp?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }
   Ingresospers(): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/ingresospers?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   // CentAsis(id_beneficiario: string, n_reg: string, id_aten: string): Observable<any> {
   //    const ruta = environment.API_URL + 'actividades-economicas/CentroAsis?token=' + userToken + '&id_beneficiario=' + id_beneficiario + '&n_reg=' + n_reg + '&id_aten=' + id_aten;
   //    return this.http.get<any>(ruta, { headers: httpHeaders});
   // }
   CentAsis(id: string, n_reg: string, id_aten: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/detallecent?token=' + userToken + '&id=' + id + '&n_reg=' + n_reg + '&id_aten=' + id_aten );
   }
   DetalleCaso(id: string, n_reg: string, id_aten: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/detalle?token=' + userToken + '&id=' + id + '&n_reg=' + n_reg + '&id_aten=' + id_aten );
   }
   DetalleCasoAprob(id: string, n_reg: string, id_aten: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/detalleaprob?token=' + userToken + '&id=' + id + '&n_reg=' + n_reg + '&id_aten=' + id_aten );
   }
   DetalleFarm(id_beneficiario: string, n_reg: string, cod:string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/detallefar?token=' + userToken + '&id_beneficiario=' + id_beneficiario + '&n_reg=' + n_reg + '&cod=' + cod  );
   }
   DetalleFamil(id_beneficiario: string, n_reg: string, cod:string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/detallefamil?token=' + userToken + '&id_beneficiario=' + id_beneficiario + '&n_reg=' + n_reg + '&cod=' + cod  );
   }
   eliminaraperfar(data: any): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/eliminar/eliminaraperfar?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   egrefamil(data: any): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/egresar/egrefamil?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   egrepers(data: any): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/egresar/egrepers?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   eliminaraperreem(data: any): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/eliminar/eliminaraperreem?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   eliminaraperamp(data: any): Observable<any> {
      const ruta = environment.API_URL + 'actividades-economicas/eliminar/eliminaraperamp?token=' + userToken;
      return this.http.post<any>(ruta, data, { headers: httpHeaders });
   }
   
   DetalleReem(id_beneficiario: string, n_reg: string, cod:string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/detallereem?token=' + userToken + '&id_beneficiario=' + id_beneficiario + '&n_reg=' + n_reg + '&cod=' + cod  );
   }
   DetalleAmp(id_beneficiario: string, n_reg: string, cod:string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'actividades-economicas/buscar/detalleamp?token=' + userToken + '&id_beneficiario=' + id_beneficiario + '&n_reg=' + n_reg + '&cod=' + cod  );
   }
   aprobacion(data): Observable<any> {
      return this.http.post(environment.API_URL + 'aprobacion?token=' + userToken, data, { headers: httpHeaders });
   }

   // CentAsis(id_beneficiario: string, n_reg: string, id_aten: string): Observable<any> {
   //    return this.http.get<any>(environment.API_URL + 'actividades-economicas/CentroAsis?token=' + userToken + '&id_beneficiario=' + id_beneficiario + '&n_reg=' + n_reg + '&id_aten=' + id_aten  );
   // }

// ----------------------------------------
// FUNCIONES PARA DECLARACIÓN Y LIQUIDACIÓN
// ----------------------------------------

   DeclaracLiquidacBuscarContrib(termino1: string, termino2: number): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'declaracion-liquidacion/buscar/contribuyente?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2);
   }

   DeclaracLiquidacBuscarDeclaracPorContrib(id: string, recibo: string): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'declaracion-liquidacion/buscar/declaracion-contribuyente?token=' + userToken + '&idu=' + id + '&idd=' + recibo);
   }

   DeclaracLiquidacMarcarRevisada(data): Observable<any> {
      return this.http.post(environment.API_URL + 'declaracion-liquidacion/revisada?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA RECAUDACIÓN
// ----------------------------------------

   RecaudacBuscarContrib(termino1: string, termino2: number): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'recaudacion/buscar/contribuyente?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2);
   }

   RecaudacBuscarPagosPorContrib(id: string, recibo: any = 0): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'recaudacion/buscar/pagos-contribuyente?token=' + userToken + '&id=' + id + '&recibo=' + recibo);
   }

  

  
   RecaudacValidarItem(data): Observable<any> {
      return this.http.post(environment.API_URL + 'recaudacion/validar-item?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA CONCILIACIÓN
// ----------------------------------------

   ConciliacBuscar(term1: any, term2: any, term3: any): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'conciliacion?token=' + userToken + '&termino1=' + term1 + '&termino2=' + term2 + '&termino3=' + term3);
   }

   ConciliacValidarItem(data): Observable<any> {
      return this.http.post(environment.API_URL + 'conciliacion/validar-item?token=' + userToken, data, { headers: httpHeaders });
   }

// ----------------------------------------
// FUNCIONES PARA INMUEBLE
// ----------------------------------------

   InmuebleBuscarContrib(termino1: string, termino2: number): Observable<any> {
      return this.http.get<any>(environment.API_URL + 'inmueble/buscar/contribuyente?token=' + userToken + '&termino1=' + termino1 + '&termino2=' + termino2);
   }
   
   InmuebleBuscarInmuebles(usuario: string): Observable<any> {
      return this.http.get(environment.API_URL + 'inmueble/buscar/inmuebles?token=' + userToken + '&usuario=' + usuario, { headers: httpHeaders });
   }
   
   InmuebleBuscar(usuario: string, inmueble: string): Observable<any> {
      return this.http.get(environment.API_URL + 'inmueble/buscar?token=' + userToken + '&usuario=' + usuario + '&inmueble=' + inmueble, { headers: httpHeaders });
   }
   
   InmuebleAgregar(data): Observable<any> {
      return this.http.post(environment.API_URL + 'inmueble/guardar?token=' + userToken, data, { headers: httpHeaders });
   }
   
   InmuebleActualizar(data): Observable<any> {
      return this.http.post(environment.API_URL + 'inmueble/actualizar?token=' + userToken, data, { headers: httpHeaders });
   }

   InmuebleImagenSubir(control: string, file: any, idi: string): Observable<any> {
      const ruta = environment.API_URL + 'inmueble/imagen/subir?token=' + userToken + '&idi=' + idi + '&control=' + control;
      const formData: FormData = new FormData();
      formData.append(control, file);
      return this.http.post(ruta, formData);
   }

   InmuebleSelects(): Observable<any> {
      return this.http.get(environment.API_URL + 'inmueble/selects?token=' + userToken, { headers: httpHeaders });
   }


   BancoLeer(): Observable<any> {
      const ruta = environment.API_URL + 'banco?token=' + userToken;
      return this.http.get<any>(ruta, { headers: httpHeaders});
   }

   BancoGuardar(data): Observable<any> {
      return this.http.post(environment.API_URL + 'banco?token=' + userToken, data, { headers: httpHeaders });
   }


}