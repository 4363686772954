export class MenuConfig {
   public defaults: any = {
      header: {
         self: {},
         items: []
      },
      aside: {
         self: {},
         items: [
            // {
            // 	title: 'Banco',
            // 	root: true,
            // 	icon: 'fa fa-piggy-bank',
            // 	page: '/banco'
            // },
            {
               title: 'Inicio',
               root: true,
               icon: 'fa fa-home',
               page: '/dashboard'
            },
            {section: 'Personal'},
           
            {
               title: 'Personal Afiliado',
               root: true,
               icon: 'fa fa-tasks',
               page: '/pers-afil',
               permission: 'PersAfil'
            },
            {
               title: 'Egreso del Personal',
               root: true,
               icon: 'fa fa-arrow-circle-left',
               page: '/egres-pers',
               permission: 'EgresPers'
            },
            {
               title: 'Familiar',
               root: true,
               icon: 'fas fa-users',
               page: '/familiar',
               permission: 'familiar'
            },
            
            
            {section: 'Atención'},
            {
               title: 'Call Center',
               root: true,
               icon: 'fa fa-phone',
               page: '/call-center',
               permission: 'CallCenter'
            },
            {
               title: 'Teleconsulta',
               root: true,
               icon: 'fas fa-user-injured',
               page: '/teleconsulta',
               permission: 'Teleconsulta'
            },
            {
               title: 'Servicio Médico',
               root: true,
               icon: 'fas fa-hospital',
               page: '/ser-med',
               permission: 'SerMed'
            },
            {
               title: 'Emergencia',
               root: true,
               icon: 'fas fa-user-nurse',
               page: '/emergencia',
               permission: 'Emergencia'
            },
            {
               title: 'Servicio Funerario',
               root: true,
               icon: 'fas fa-user-plus',
               page: '/ser-fun',
               permission: 'SerFun'
            },
            {
               title: 'Servicio Ambulancia',
               root: true,
               icon: 'fa fa-truck',
               page: '/ser-amb',
               permission: 'SerAmb'
            },
            {
               title: 'Carta Compromiso',
               root: true,
               icon: 'fa fa-edit',
               page: '/carta-comp',
               permission: 'CartComp'
            },
           
            
            {
               title: 'Farmacias',
               root: true,
               icon: 'fas fa-vials',
               page: '/farmacias',
               permission: 'Farmacias'
            },
            {
               title: 'Reembolso',
               root: true,
               icon: 'fas fa-id-card-alt',
               page: '/reembolso',
               permission: 'Reembolso'
            },
            {
               title: 'Atención Médica Primaria',
               root: true,
               icon: 'fas fa-thermometer',
               page: '/amp',
               permission: 'amp'
            },
            {
               title: 'Reclamos',
               root: true,
               icon: 'fas fa-receipt',
               page: '/reclamos',
               permission: 'Reclamos'
            },
            
            
            {section: 'Adminisión'},
           
           
            {
               title: 'Adminisión',
               root: true,
               icon: 'fas fa-person-booth',
               page: '/admision',
               permission: 'Admision'
            },
            {section: 'Aprobación'},
            {
               title: 'Aprobación',
               root: true,
               icon: 'fas fa-check-double',
               page: '/aprob',
               permission: 'Aprob'
            },
            // {
            //    title: 'Conciliación',
            //    root: true,
            //    icon: 'fa fa-tasks',
            //    page: '/conciliacion',
            //    permission: 'Conciliacion'
            // },
            {section: 'Reportes'},
            {
               title: 'Historia Teleconsulta',
               root: true,
               icon: 'flaticon-notepad',
               page: '/historia',
               permission: 'Historia'
            },
            {
               title: 'Estadística Mensual',
               root: true,
               icon: 'fa fa-chart-line',
               page: '/estadistica-mensual',
               permission: 'EstadisticaMensual'
            },
             
            {section: 'Configuraciones'},
            {
               title: 'Usuarios',
               bullet: 'dot',
               icon: 'fa fa-users-cog',
               permission: 'ConfiguracionUsuarios',
               submenu: [
                  {
                     title: 'Usuarios',
                     page: '/user-management/users',
                     permission: 'ConfiguracionUsuariosUsuarios'
                  },
                  {
                     title: 'Roles',
                     page: '/user-management/roles',
                     permission: 'ConfiguracionUsuariosRoles'
                  }
               ]
            },
            {
               title: 'Centro Asistencial',
               root: true,
               icon: 'fas fa-plus-square',
               page: '/cent-asis',
               permission: 'CentAsis'
            },
            // {
            //    title: 'Base de Cálculo',
            //    root: true,
            //    icon: 'fa fa-calendar-alt',
            //    page: '/configuracion/utrimza',
            //    permission: 'ConfiguracionUtrimza'
            // },
            // {
            //    title: 'Petro',
            //    root: true,
            //    icon: 'fa fa-ruble-sign',
            //    page: '/configuracion/petro',
            //    permission: 'ConfiguracionPetro'
            // },
            // {
            //    title: 'Conceptos',
            //    root: true,
            //    icon: 'fa fa-address-book',
            //    page: '/configuracion/conceptos',
            //    permission: 'ConfiguracionConceptos'
            // },
            // {
            //    title: 'Actividad Económica',
            //    root: true,
            //    bullet: 'dot',
            //    icon: 'fa fa-archive',
            //    permission: 'ConfiguracionActividadEconomica',
            //    submenu: [
            //       {
            //          title: 'Grupos',
            //          page: '/configuracion/actividad-economica/grupos',
            //          permission: 'ConfiguracionActividadEconomicaGrupos'
            //       },
            //       {
            //          title: 'Subgrupos',
            //          page: '/configuracion/actividad-economica/subgrupos',
            //          permission: 'ConfiguracionActividadEconomicaSubgrupos'
            //       }
            //    ]
            // }
         ]
      },
   };

   public get configs(): any {
      return this.defaults;
   }
}
